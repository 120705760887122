import React from 'react';
import '../components/styles/News.css';
import { useTranslation } from 'react-i18next';
import vaccinePDF from '../assets/energy_sys/16.PDF';
import vaccinePDF1 from '../assets/energy_sys/41.PDF';
import vaccinePDF2 from '../assets/energy_sys/plan.PDF';

const EnergyManagement = () => {
  const { t } = useTranslation();

  return (
    <div className="EnergyContainer">
      <div className="newsSection">
        <div className="pdfContainer">
          {/* PDFs will be shown on larger screens */}
          <div className="pdfViewerContainer">
            <embed src={vaccinePDF} type="application/pdf" className="pdfViewer" />
            <embed src={vaccinePDF1} type="application/pdf" className="pdfViewer" />
            <embed src={vaccinePDF2} type="application/pdf" className="pdfViewer" />
          </div>

          {/* Buttons for download will appear on mobile */}
          <div className="mobileDownloadButtons">
            {/*<a href={vaccinePDF} download className="downloadButton">{t("Download PDF 16")}</a>*/}
            {/*<a href={vaccinePDF1} download className="downloadButton">{t("Download PDF 41")}</a>*/}
            {/*<a href={vaccinePDF2} download className="downloadButton">{t("Download PDF Plan")}</a>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnergyManagement;
