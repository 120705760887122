import React from 'react';
import '../components/styles/News.css';
import { useTranslation } from 'react-i18next';
import rarFile1 from '../assets/energy_sys/2022.rar';
import rarFile2 from '../assets/energy_sys/2023.rar';
import rarFile3 from '../assets/energy_sys/2024.rar';

const EnergyManagement = () => {
  const { t } = useTranslation();

  return (
    <div className="EnergyContainer">
      <div className="newsSection">
        <h2>2022 рік</h2>
        <a href={rarFile1} download>
          <button>Завантажити Архів </button>
        </a>
      </div>

      <div className="newsSection">
        <h2>2023 рік</h2>
        <a href={rarFile2} download>
          <button>Завантажити Архів </button>
        </a>
      </div>

      <div className="newsSection">
        <h2>2024 рік</h2>
        <a href={rarFile3} download>
          <button>Завантажити Архів </button>
        </a>
      </div>
    </div>
  );
};

export default EnergyManagement;
