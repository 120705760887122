import React from 'react';
import '../components/styles/News.css';
import { useTranslation } from 'react-i18next';
import vaccineImage from '../assets/news.jpg'; // Replace with the correct path to your image
import vaccineImageRab from '../assets/newsRabbit.png'; // Replace with the correct path to your image
import Green from '../assets/newsGreen.jpg'; // Replace with the correct path to your image
import Deratez from '../assets/news_Deratez.png'; // Replace with the correct path to your image
import Collegues from '../assets/for_news/MyCollages.png'; // Replace with the correct path to your image

const News = () => {
  const { t } = useTranslation();

  return (
      <div className="newsContainer">
        <img src={vaccineImage} alt={t("Vaccine Products")} className="newsImage"/>
        <div className="newsSection">
          <h2>{t("Шановні клієнти!")}</h2>
          <p>{t("Ми раді повідомити, що у нас в наявності:")}</p>
          <ul>
            <li>{t("Вакцина проти сибірки тварин – фасування по 50 доз у флаконі, вартість одного флакона – 231 грн.")}</li>
            <p>{t("📌 Звертаємо вашу увагу, що на вакцину проти сибірки діють гнучкі знижки залежно від об’єму закупівлі!")}</p>
            <li>{t("Вакцина проти пастерельозу птиці.")}</li>
            <li>{t("Вакцина проти геморагічної хвороби кролів.")}</li>
            <li>{t("Вакцина проти міксоматозу кролів.")}</li>
            <li>{t("Туберкулін для ссавців.")}</li>
            <li>{t("Туберкулін для птиці.")}</li>
            <li>{t("Засіб для боротьби з мишовидними гризунами 'Дератез'.")}</li>
            <li>{t("Тест-система для визначення маститу 'Маститест-С'.")}</li>
            <li>{t("Алерген ААМ та інші види продукції.")}</li>
          </ul>
          <p>{t("🔔 У зв’язку зі складною безпековою ситуацією ми працюємо у нестабільному режимі, але робимо все можливе, щоб забезпечити вас необхідною продукцією.")}</p>
          <p>{t("📦 Поспішайте зробити замовлення, поки продукція є в наявності!")}</p>
          <p>{t("Для уточнення деталей, умов співпраці та замовлення звертайтеся за телефоном:")}</p>
          <p>
            📞 {t("099-420-3545")}<br/>
            📞 {t("068-420-3545")}
          </p>
          <p>{t("Дякуємо, що обираєте Сумську біологічну фабрику!")}</p>
        </div>

        <div className="newsSection">
          <img src={vaccineImageRab} alt={t("Rabbit Vaccine")} className="rabbitDiseaseImage"/>
          <div className="rabbitDiseaseContent">
            <h2>{t("Геморагічна хвороба кролів – серйозна загроза для вашого пухнастого друга!")}</h2>
            <p>{t("🛡 Захистіть своїх кролів вже сьогодні!")}</p>
            <ul>
              <li>{t("✔️ Вакцини та пробіотики – ваші надійні союзники в боротьбі з цією небезпечною хворобою.")}</li>
              <li>{t("✔️ Вакцини – ваш кращий шанс на захист від геморагічної хвороби кролів.")}</li>
              <li>{t("✔️ Пробіотики – сприяють підтримці здорової шлунково-кишкової системи та зміцнюють імунітет.")}</li>
            </ul>
            <p>{t("👉 Не чекайте, поки буде пізно! Подбайте про здоров’я своїх тварин вже зараз!")}</p>
            <p>{t("📣 Сумська біологічна фабрика – це понад 90 років досвіду у виробництві ветеринарної продукції.")}</p>
            <p>{t("⭐️ Продукція, якій довіряють професіонали! ⭐️")}</p>
            <p>{t("📣 Поділіться своїм досвідом: як ви забезпечуєте здоров’я кролів? Ми цінуємо вашу думку!")}</p>
            <p>{t("📞 Для додаткової інформації про вакцини та пробіотики для боротьби з геморагічною хворобою кролів звертайтеся за контактами:")}</p>
            <h3>Контакти:</h3>
            <p>
              📞 Телефони:<br/>
              {t("099-420-35-45 (viber)")}<br/>
              {t("073-420-35-45 (viber)")}<br/>
              {t("068-420-35-45")}
            </p>
            <p>📧 {t("Електронна пошта:")} sgbiofabrika@gmail.com</p>
            <p>
              🌐 Сайт: <a href="https://www.biofabrika.sumy.ua/" target="_blank"
                         rel="noopener noreferrer">https://www.biofabrika.sumy.ua/</a>
            </p>
          </div>


        </div>
        <div className="newsSection">
          <img src={Green} alt={t("Rabbit Vaccine")} className="rabbitDiseaseImage"/>

          <h2>📢 {t("Сумська біологічна фабрика на виставці 'Agro Animal Show'!")}</h2>
          <p>
            {t(
                "З 12 по 14 лютого 2025 року запрошуємо вас відвідати наш стенд на виставці 'Agro Animal Show', яка проходитиме в МВЦ у місті Київ. 🏢"
            )}
          </p>
          <p>{t("На нашому стенді ви матимете змогу ближче ознайомитися з продукцією фабрики. 🧪")}</p>
          <p>
            {t(
                "✅ Сумська біологічна фабрика – державне підприємство, що входить до складу Держспоживслужби, вже понад 90 років забезпечує ветеринарну медицину України якісними препаратами. За цей час ми здобули довіру фермерів та ветеринарних лікарів по всій країні. 🐄🐑"
            )}
          </p>
          <p>
            {t(
                "🔬 Ми спеціалізуємося на виробництві вакцин, ліків та діагностикумів для профілактики й діагностики небезпечних інфекцій у тварин."
            )}
          </p>
          <p>{t("🤝 З нетерпінням чекаємо на вашу присутність! До зустрічі на виставці! 🌟")}</p>
        </div>

        {/* Section 3: Rodent Control */}
        <div className="newsSection">
          <img src={Deratez} alt={t("Rabbit Vaccine")} className="rabbitDiseaseImage"/>

          <h2>🛡️ {t("Позбавтеся гризунів швидко та ефективно!")}</h2>
          <p>{t("🔹 Ефективність: Загибель гризунів протягом 5-14 днів.")}</p>
          <p>{t("🔹 Простота у використанні: Легке розкладання у місцях проживання шкідників.")}</p>
          <p>{t("🔹 Дієвість: 1-2 г препарату – і гризуни зникнуть!")}</p>
          <h3>{t("Переваги:  ")}</h3>

          <p>{t("✅ Універсальність: підходить для використання у фермах, приміщеннях та угіддях.")}</p>
          <p>{t("✅ Економічність: до 500 м² захисту на 450 г засобу.")}</p>
          <p>{t("✅ Довготривалий ефект: ефективна дія навіть після першого використання.")}</p>

          <h3>⚠️ {t("Застереження: ")}</h3>

          <p>{t("🔸 Уникайте контакту зі шкірою. ✋")}</p>
          <p>{t("🔸 Не застосовуйте за температури вище 20°C. 🌡")}</p>
          <p>{t("🔸 Зберігайте в темному, прохолодному місці (2–8 °C). ❄️🌑")}</p>
          <p>{t("🔸 Не застосовувати для знищення гризунів на кролефермах та на фермах з вирощування нутрій, шиншил тощо, а також у віваріях. 🐇🐀")}</p>

          <p>
            {t("📍 Вироблено в Україні.")}<br/>
            {t("👉 Замовляйте Дератез за привабливою ціною вже сьогодні і насолоджуйтесь безпроблемним середовищем без гризунів завтра. 🌿")}
          </p>
        </div>


        <div className="newsSection">
          <h2>📢 Сумська біологічна фабрика на "Agro Animal Show 2025" –
            успішна участь! 🚜🐄</h2>
          <p>
            З 12 по 14 лютого ми стали частиною <strong>"Agro Animal Show 2025"</strong>, яка відбулася в Міжнародному
            виставковому центрі в Києві. Це були три насичені дні спілкування, нових знайомств та обміну досвідом!
          </p>
          <p>
            Ми раді, що мали можливість представити нашу продукцію, обговорити актуальні виклики галузі та знайти нових
            партнерів. Дякуємо організаторам за чудову подію, а всім гостям нашого стенду – за цікаві розмови та вашу
            довіру!
          </p>
          <p>До нових зустрічей! 🚀</p>

          <img src={Collegues} alt={t("Collegues")} className="rabbitDiseaseImage"/>
        </div>

      </div>


  );
};

export default News;
